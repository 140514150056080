const getUid = (ceData) => {
  return ceData.id;
};

const getCeMainHeadlineTitle = (ceData) => {
  return ceData.headerParts?.header?.title;
};

const getCeMainHeadlineLayout = (ceData) => {
  return ceData.headerParts?.header?.layout;
};

const getCeSubHeadlineTitle = (ceData) => {
  return ceData.headerParts?.subheader?.subheader.title;
};

const getCeHeaderLink = (ceData) => {
  return ceData.headerParts?.link;
};

const getShowInOnPageNavigation = (ceData) => {
  return ceData.properties?.showInOnPageNavigation;
};

const getCeDataByPath = (ceData, path) =>
  path
    .split(".")
    .reduce(
      (obj, prop) => (obj && obj.hasOwnProperty(prop) ? obj[prop] : undefined),
      ceData
    );

export default {
  getUid,
  getCeMainHeadlineTitle,
  getCeMainHeadlineLayout,
  getCeSubHeadlineTitle,
  getCeHeaderLink,
  getShowInOnPageNavigation,
  getCeDataByPath,
};
