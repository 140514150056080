import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import NextImage from "next/image";

const Image = ({
  src,
  srcSet,
  sizes,
  className,
  wrapperClassName = "",
  lazy = true,
  handleLazyLoad = true,
  nextOptimizedImage = false,
  width,
  height,
  layout = "responsive",
  priority = false,
  alt = "",
  title = "",
}) => {
  if (!src) return;

  if (nextOptimizedImage) {
    return (
      <NextImage
        src={src}
        width={width}
        height={height}
        className={className}
        layout={layout}
        priority={priority}
        alt={alt}
        title={title}
        loading={!priority && lazy ? "lazy" : undefined}
      />
    );
  }

  if (!lazy) {
    return (
      <img
        src={src}
        srcSet={srcSet}
        className={`image ${className}`}
        alt={alt}
        title={title}
      />
    );
  }
  if (lazy && !handleLazyLoad) {
    return (
      <img
        data-src={src}
        data-srcset={srcSet}
        className={`image ${className}`}
        alt={alt}
        title={title}
      />
    );
  }
  return (
    <LazyLoadImage
      src={src}
      srcSet={srcSet}
      sizes={sizes}
      className={`image ${className}`}
      wrapperClassName={wrapperClassName}
      alt={alt}
      title={title}
    />
  );
};
export default Image;
