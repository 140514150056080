import React from "react";
import styles from "./CeHeader.module.scss";
import Link from "next/link";
import Button from "../../../../../components/Button";
/**
 * Render header of ce element
 *
 * @param props
 * @returns {XML}
 */
const ceHeader = ({ header, subHeader, link, headerLayout }) => {
  if (header) {
    switch (parseInt(headerLayout)) {
      case 1:
        header = <h1 dangerouslySetInnerHTML={{ __html: header }} />;
        break;
      case 2:
        header = <h2 dangerouslySetInnerHTML={{ __html: header }} />;
        break;
      case 3:
        header = <h3 dangerouslySetInnerHTML={{ __html: header }} />;
        break;
      case 4:
        header = <h4 dangerouslySetInnerHTML={{ __html: header }} />;
        break;
      default:
        header = <h3 dangerouslySetInnerHTML={{ __html: header }} />;
    }

    if (subHeader) {
      subHeader = <p>{subHeader}</p>;
    }
  } else {
    return null;
  }

  return (
    <div className={`ce__header ${styles.ce_header}`}>
      {subHeader && (
        <div className={styles.ce__header__subheadline}>{subHeader}</div>
      )}
      {header && (
        <div className={styles.ce__header__mainheadline}>{header}</div>
      )}
      {link && (
        <div className={`${styles.ce__header_link} ce__header_link`}>
          <Link href={link.href} passHref prefetch={false}>
            <Button text={link.text} layout="secondary" />
          </Link>
        </div>
      )}
    </div>
  );
};

export default ceHeader;
